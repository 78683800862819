<template>
  <div class="dropzone mb-1 dz-clickable"
       :class="[multiple ? 'dropzone-multiple': 'dropzone-single']">
    <div class="fallback">
      <div class="custom-file">
        <input type="file"
               class="custom-file-input"
               id="projectCoverUploads"
               :multiple="multiple">
        <label class="custom-file-label" for="projectCoverUploads">Choose file</label>
      </div>
    </div>
    <div class="dz-preview dz-preview-single"
         v-if="!multiple"
         :class="previewClasses"
         ref="previewSingle">
      <!--<button data-dz-remove="true" class="btn btn-danger btn-sm" style="position: absolute">
        <i class="fas fa-trash"></i>
      </button>-->
      <div class="dz-preview-cover">
        <img class="dz-preview-img" data-dz-thumbnail>
      </div>
    </div>
    <ul v-else
        class="dz-preview dz-preview-multiple list-group list-group-lg list-group-flush"
        :class="previewClasses"
        ref="previewMultiple">
      <li class="list-group-item px-0">
        <div class="row align-items-center">
          <div class="col-auto">
            <div class="avatar">
              <img class="avatar-img rounded" data-dz-thumbnail>
            </div>
          </div>
          <div class="dz-custom-li-list-name">
            <h4 class="mb-1 txt-ellipsis" data-dz-name>...</h4>
            <p class="small text-muted mb-0" data-dz-size>...</p>
          </div>
          <div class="col-auto">
            <button data-dz-remove="true" class="btn btn-sm font-size-1em">
              <i class="fas fa-trash"></i>
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
  export default {
    name: 'dropzone-file-upload',
    props: {
      options: {
        type: Object,
        default: () => ({})
      },
      value: [String, Object, Array],
      url: {
        type: String,
        default: 'http://'
      },
      multiple: Boolean,
      previewClasses: [String, Object, Array],
      scroll: Boolean,
      maxFiles : {
        type: Number,
        default : 1
      },
      maxFilesize : {
        type: Number,
        default : 10 // 단위 mb
      },
      acceptedFiles: String,
      imageData: String
    },
    model: {
      prop: 'value',
      event: 'change'
    },
    data() {
      return {
        currentFile: null,
        files: [],
        showList: false,
      }
    },
    watch: {
      imageData() {
        this.addBase64Image(this.imageData);
      }
    },
    created() {
      this.initDropzone();
    },
    methods: {
      /**
       * Base64형태의 데이터를 받아 이미지를 화면에 drawing
       * @param dataURI
       */
      addBase64Image(dataURI) {
        let byteString, mimestring;
        if(dataURI.split(',')[0].indexOf('base64') !== -1 ) {
          byteString = atob(dataURI.split(',')[1])
        } else {
          byteString = decodeURI(dataURI.split(',')[1])
        }

        mimestring = dataURI.split(',')[0].split(':')[1].split(';')[0]

        let content = [];
        for (let i = 0; i < byteString.length; i++) {
          content[i] = byteString.charCodeAt(i)
        }

        this.dropzone.addFile(new Blob([new Uint8Array(content)], {type: mimestring}));
      },

      async initDropzone() {
        let Dropzone = await import('dropzone')
        Dropzone = Dropzone.default || Dropzone
        Dropzone.autoDiscover = false
        let preview = this.multiple ? this.$refs.previewMultiple : this.$refs.previewSingle;
        let self = this
        const removeFile = (file) => {
          let index = this.files.findIndex(f => f.upload.uuid === file.upload.uuid)
          if (index !== -1) {
            this.files.splice(index, 1);
          }
        };

        let finalOptions = {
          ...this.options,
          url: this.url,
          thumbnailWidth: null,
          thumbnailHeight: null,
          previewsContainer: preview,
          previewTemplate: preview.innerHTML,
          maxFiles: (!this.multiple) ? 1 : this.maxFiles,
          maxFilesize: this.maxFilesize,
          acceptedFiles: !this.acceptedFiles ? 'image/*' : this.acceptedFiles,
          init: function () {
            this.on("addedfile", function (file, event) {
              if(!self.multiple && self.currentFile) self.dropzone.removeFile(self.currentFile);

              let type = file.type;
              if(type.indexOf("image") < 0) { // 이미지가 아닌 파일은.
                let iconClass = ''
                switch (type) {
                  case 'application/pdf':
                    iconClass = 'fa-file-pdf'
                    break;
                  case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    iconClass = 'fa-file-excel'
                    break;
                  case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                    iconClass = 'fa-file-word'
                    break;
                  case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                    iconClass = 'fa-file-powerpoint'
                    break;
                  default:
                    iconClass = 'fa-file'
                    break;
                }

                let avatarTarget = file.previewElement.querySelector(".avatar");
                if(avatarTarget) {
                  avatarTarget.firstElementChild.remove()
                  avatarTarget.classList.add('far', iconClass, 'font-size-2em')
                }
              }

              self.currentFile = file;
            })
          }
        };

        this.dropzone = new Dropzone(this.$el, finalOptions);
        preview.innerHTML = '';

        let evtList = ["drop", "dragstart", "dragend", "dragenter", "dragover", "dragleave", "addedfile", "addedfiles", "removedfile", "thumbnail", "error", "errormultiple", "processing", "processingmultiple", "uploadprogress", "totaluploadprogress", "sending", "sendingmultiple", "success", "successmultiple", "canceled", "canceledmultiple", "complete", "completemultiple", "reset", "maxfilesexceeded", "maxfilesreached", "queuecomplete"];
        evtList.forEach(evt => {
          this.dropzone.on(evt, (data) => {
            this.$emit(evt, data);
            if (evt === 'addedfile') {
              if(this.files.length < this.maxFiles) {
                this.files.push(data);
                this.$emit('change', this.files);
              }
            } else if (evt === 'removedfile') {
              removeFile(data);
              this.$emit('change', this.files);
            } else if(evt === 'maxfilesexceeded') {
              if(this.multiple) this.dropzone.removeFile(data);
            }
          })
        });

        if (this.imageData) {
          this.addBase64Image(this.imageData);
        }
      }
    },
  }
</script>
<style>
  .font-size-1em {
    font-size: 1em;
  }
  .font-size-2em {
    font-size: 2em;
  }
</style>
